import React from 'react';
import { Global } from '@emotion/core';
import styled from '@emotion/styled';
import { MDXProvider } from '@mdx-js/react';
import PropTypes from 'prop-types';
import {
    A,
    GoogleTagManager,
    Footer,
    Header,
    SEO,
    YouTubeEmbed,
    // text elements
    BlockQuote,
    StyledDate,
    H1,
    H2,
    H3,
    H4,
    H5,
    P,
    // layouts
    HR,
    layoutContainer,
    spacings,
    // styles
    GlobalStyles,
} from '../../../../packages/shared';

const headline = styled(H2)`
  margin-bottom: ${spacings.header};
`;

const sectionHeader = styled(H3)`
  margin-bottom: ${spacings.header};
`;

const components = {
    YouTubeEmbed,
    h1: H1,
    h2: headline,
    h3: sectionHeader,
    h4: H4,
    h5: H5,
    p: P,
    a: A,
    blockquote: BlockQuote,
    hr: HR,
};

const BlogPostContainer = styled('div')`
  ${layoutContainer}
`;

const BlogPostSection = styled('div')`
  margin-bottom: ${spacings.section};
`;

const BlogPost = ({ children, pageContext }) => {
    const { frontmatter } = pageContext;
    const { author, date, description, slug, title } = frontmatter;

    const formattedDate = new Date(date).toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
    });

    return (
        <MDXProvider components={components}>
            <GoogleTagManager />
            <Global styles={GlobalStyles} />
            <SEO
                title={title}
                description={description}
                author={author}
                slug={slug}
                siteData={{ siteMetadata: { siteUrl: 'https://blog.ericaashley.com' }}}
            />
            <Header />
            <BlogPostContainer>
                <main>
                    <BlogPostSection>
                        <H1>{title}</H1>
                        <StyledDate>{formattedDate}</StyledDate>
                    </BlogPostSection>

                    <BlogPostSection>{children}</BlogPostSection>
                </main>
            </BlogPostContainer>
            <Footer />
        </MDXProvider>
    );
};

BlogPost.propTypes = {
    children: PropTypes.any,
    pageContext: PropTypes.object,
};

export default BlogPost;
