import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/path0/packages/blog/src/layouts/blog-post.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const YouTubeEmbed = makeShortcode("YouTubeEmbed");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`So you've left your job for an immersive developer program...`}</h2>
    <hr></hr>
    <p>{`Hi, my name is Erica. I'm just going to cut to the chase here: I recently left a job that I loved and tomorrow will be my first day as part of `}<a parentName="p" {...{
        "href": "http://www.launchacademy.com/"
      }}>{`Launch Academy's Spring 2015 cohort`}</a>{`, where I will be spending the next ten weeks learning web development.`}</p>
    <hr></hr>
    <h3>{`Background`}</h3>
    <p>{`For the past three years, I had been working as a data analyst for a national education non-profit organization. It was an incredible experience. I was part of an amazing team. I worked on interesting projects, was constantly learning, and I could sleep at night knowing that the work I did was contributing toward a larger mission that would have a meaningful impact in the lives of students across the country.`}</p>
    <p>{`So why leave that behind? Well, as I worked with more and more data, I started gravitating toward programming tools as a way to help me manage data and share information with others. I attended Meetups, took a range of Coursera classes, and found that the more I learned, the more data I could work with and the more efficiently I could work.`}</p>
    <hr></hr>
    <h3>{`The more I learned, the more I learned about how much I didn't know.`}</h3>
    <p>{`Through Meetup groups, I got to see how other people worked with data -- whether it was creating `}<a parentName="p" {...{
        "href": "http://mbtaviz.github.io/"
      }}>{`data visualizations of MBTA ridership patterns`}</a>{`, or how people were using APIs to do really cool things.`}</p>
    <p>{`There was beauty in a lot of what I saw, both visually and intellectually. One of the main reasons I love working with data is for its ability to lead us to a deeper understanding of human nature, whether it's about how students learn or how people live their day-to-day lives. So I wanted to incorporate these methods and tools into my own work -- I just had to figure out how.`}</p>
    <p>{`I first heard about programming bootcamps from a career panel hosted by the `}<a parentName="p" {...{
        "href": "http://boston.pyladies.com/"
      }}>{`Boston PyLadies`}</a>{` group. It was an interesting concept to me, so I did a little research on bootcamps in Boston -- only to find that these programs were all across the country, in all shapes and sizes.`}</p>
    <hr></hr>
    <h3>{`Why Launch Academy?`}</h3>
    <p>{`After researching a variety of these programs, Launch Academy stood out to me as my first choice, by far. In my mind, if I was going to leave my job for a programming bootcamp, a solid curriculum had to be a given.`}</p>
    <p>{`What really made Launch Academy stand out and pushed me to apply though, was the culture, their instructional approach, and their development philosophies.`}</p>
    <p>{`It was clear that they had really thought through the program as a learning experience, and not just a means to an end. The environment and culture at my last job did a lot to shape and help me better define and articulate my values. So when I saw how much Launch Academy emphasized the importance of collaboration and community involvement, I knew this was an opportunity worth pursuing. Not only would I be able to learn what I was set out to do, but here was a place whose values and learning approach aligned with what I was looking for.`}</p>
    <p>{`So lucky for me, they accepted my application for this Spring Cohort and here we are.`}</p>
    <hr></hr>
    <h3>{`Tomorrow Is My Turn`}</h3>
    <p>{`I thought through every reason to pursue this learning opportunity or not -- what I would be leaving behind, what I could potentially do with this kind of experience. Knowing the tech industry's reputation for diversity, or lack thereof -- I thought about what it would mean to leave an organization that had so many strong, inspiring women in leadership. What it would mean to leave such an incredible team. And what it would mean to leave a cause that I care deeply about.`}</p>
    <p>{`I've been thinking about women in STEM a lot recently, and all practicalities aside, this also played an important role in my decision to take this path. I didn't want these reports about the lack of diversity in tech, or lack of women working in STEM to deter me from pursuing this kind of work.`}</p>
    <p><a parentName="p" {...{
        "href": "http://www.rhiannongiddens.com/"
      }}>{`Rhiannon Giddens`}</a>{` just released her first solo album last week, called `}<a parentName="p" {...{
        "href": "http://www.npr.org/2015/02/01/382372052/first-listen-rhiannon-giddens-tomorrow-is-my-turn"
      }}>{`Tomorrow Is My Turn`}</a>{`. It's an album full of covers of songs `}<a parentName="p" {...{
        "href": "http://www.nonesuch.com/journal/rhiannon-giddens-carolina-chocolate-drops-solo-debut-album-t-bone-burnett-2014-11-19"
      }}>{`"by women or interpreted by women."`}</a></p>
    <p>{`I've been listening to `}<em parentName="p">{`Tomorrow Is My Turn`}</em>{` a lot lately, and it's such an amazing record. So as I do with any record I love, I started reading review after review to learn more. And this lovely `}<a parentName="p" {...{
        "href": "http://www.uncut.co.uk/blog/uncut-editors-diary/rhiannon-giddens-tomorrow-is-my-turn-reviewed-0"
      }}>{`interview with Rhiannon`}</a>{` popped up today, where she says:`}</p>
    <blockquote>
      <p parentName="blockquote">{`"But on this record I’ve been really thinking about women, and just being so grateful for the opportunities I’ve had, as a 21st Century woman in a First World country, realising that not everybody has that opportunity, and they didn’t in the past. I got really inspired by Nina Simone and Dolly Parton: these women are my heroes, they’re the reason I can do what I do."`}</p>
    </blockquote>
    <p>{`This resonated with me because she so eloquently articulated my current mindset. Having experienced life in another place and time, I know first-hand that being a 21st century woman in a first world country has granted me many things in life that I am thankful for. I am so grateful that I get to do what I do -- both for what's to come in the months ahead, and for the opportunities I've been so fortunate to have.`}</p>
    <hr></hr>
    <YouTubeEmbed videoId="DFVAoIU8vTM" mdxType="YouTubeEmbed" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      